import "./App.css";

function App() {
  return (
    <div className="App">
      <h1>Hello World!</h1>
      <p>I am in development and will be coming soon!</p>
    </div>
  );
}

export default App;
